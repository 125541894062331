body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-custom-icon {

  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  display: block;
  background-color: #ff4646;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .3);
  box-sizing: border-box;
}

.map-custom-icon-black {

  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  display: block;
  background-color: black;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .3);
  box-sizing: border-box;
  z-index: 999999 !important;
}

.accordion-item {
  background-color: white !important;
  box-shadow: white !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-left: none !important;
  border-right: none !important;
}

.accordion-header {
  background-color: white !important;
  box-shadow: white !important
}


.accordion {
  background-color: white !important;
  box-shadow: white !important
}


.accordion-button {
  background-color: white !important;
  box-shadow: white !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: rgb(33, 37, 41) !important;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: white !important
}

.accordion-button:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.wrapper {
  width: 100%;
  height: 100vh;
}

.header,
.footer {
  height: 90px;
}

main {
  flex: 1;
}

body {
  margin: 0;
}

.map-view {
  height: 80vh;
  width: 100%;
}

.footer {
  margin-top: auto;
  min-height: 90px;
}


.telegram {
  background-color: #24a1de;
  color: white !important;
}

.bot {
  background-color: #004aad;
  color: white !important;
}

.gifts {
  background-color: green;
  color: white !important;
  text-decoration: none;
}